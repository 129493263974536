import React from "react";
import { Routes, Route } from "react-router-dom";
import { withFallback } from "./withFallback";
import { ProtectedRoute } from "./ProtectedLayout";

const MainLayout = withFallback(
  React.lazy(() => import("~/layouts/MainLayout"))
);
const RegisterLayout = withFallback(
  React.lazy(() => import("~/layouts/RegisterLayout"))
);

const Login = withFallback(React.lazy(() => import("~/pages/Login")));
const Register = withFallback(React.lazy(() => import("~/pages/Register")));
const ListPage = withFallback(React.lazy(() => import("~/pages/ListPage")));
const StatusManager = withFallback(
  React.lazy(() => import("~/pages/Status/StatusManager"))
);
const MaangePool = withFallback(React.lazy(() => import("~/pages/ManagePool")));
const Keyword = withFallback(React.lazy(() => import("~/pages/Keyword")));
const Providers = withFallback(React.lazy(() => import("~/pages/Providers")));

const AccountSettings = withFallback(
  React.lazy(() => import("~/pages/Profile"))
);

const RenderRouter: React.FC = () => {
  return (
    <Routes>
      <Route element={<RegisterLayout />}>
        <Route path="/" element={<Login />} />
      </Route>
      <Route element={<RegisterLayout />}>
        <Route path="/register" element={<Register />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <ListPage />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/dashboard" element={<StatusManager />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route
          path="/domains"
          element={
            <ProtectedRoute>
              <Providers />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route element={<MainLayout />}>
        <Route
          path="/managePool"
          element={
            <ProtectedRoute>
              <MaangePool />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route element={<MainLayout />}>
        <Route
          path="/keyword"
          element={
            <ProtectedRoute>
              <Keyword />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/profile" element={<AccountSettings />} />
      </Route>
    </Routes>
  );
};

export default RenderRouter;
