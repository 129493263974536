import { io, Socket } from "socket.io-client";
import config from "~/config/config";

class SocketClient {
  socket!: Socket;
  constructor() {
    this.socket = io(config.apiUrl.replace(/\/api$/, ""), {
      transports: ["websocket"],
    });
  }

  connect() {
    return new Promise<void>((resolve, reject) => {
      this.socket.on("connect", () => resolve());
      this.socket.on("connect_error", (error) => reject(error));
    });
  }

  disconnect() {
    return new Promise<void>((resolve) => {
      this.socket.on("disconnect", () => {
        resolve();
      });
    });
  }

  emit(event: string, data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.socket) return reject("No socket connection.");

      return this.socket.emit(event, data, (response: { error?: string }) => {
        if (response.error) {
          console.error(response.error);
          return reject(response.error);
        }

        return resolve();
      });
    });
  }

  on(event: string, fun: (...args: any[]) => void): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.socket) return reject("No socket connection.");

      this.socket.on(event, fun);
      resolve();
    });
  }
}

export default SocketClient;
