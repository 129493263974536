
const getPersistedAuthData = () => {
  const root = localStorage.getItem("user");
  if (!root) {
    return undefined;
  }

  let rootObj;
  try {
    rootObj = JSON.parse(root);
  } catch {
    return undefined;
  }

  try {
    return rootObj;
  } catch {
    return undefined;
  }
};



const getPersistedToken = () => {
  const auth = getPersistedAuthData();

  return auth?.accessToken;
};
const getPersistedData = () => {
  const auth = getPersistedAuthData();

  return auth;
};

export {getPersistedToken, getPersistedData}