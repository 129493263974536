import { configureStore } from "@reduxjs/toolkit";
import { authAPI } from "~/repositories/auth.service";
import { authReducer } from "./slices/auth.slice";
import { notificationReducer } from "./slices/notification.slice";
import { appReducer } from "./slices/appSlice";

export const store = configureStore({
  reducer: {
    authSlice: authReducer,
    appReducer: appReducer,
    notifcation: notificationReducer,
    [authAPI.reducerPath]: authAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([authAPI.middleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
