import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { axiosPublic } from "~/repositories/axios";

import { getPersistedData } from "~/helpers";
export interface AuthState {
  email: string;
  id: string;
  accessToken: string;
  refreshToken: string;
  role: string;
  fullname: string;
}

const initialState: AuthState = {
  email: "",
  accessToken: "",
  refreshToken: "",
  role: "",
  fullname: "",
  id: ""
};

export const refreshToken = createAsyncThunk(
  `authSlice/refreshToken`,
  async (_, { getState }) => {
    const state = getState() as RootState;
    const {refreshToken} = getPersistedData();
    axiosPublic.defaults.headers.common['Authorization'] = `Bearer ${refreshToken}`;
    const res = await axiosPublic.get(`auth/refresh`);

    const newUser = {
      ...state.authSlice,
      role: res.data.role,
      email: res.data.email,
      fullname: res.data.fullname,
      accessToken: res.data.accessToken,
      refreshToken: res.data.refreshToken,
      id: res.data.id,
    };

    return newUser;
  }
);

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setAuthenticatedUser: (
      state: AuthState,
      { payload }: PayloadAction<AuthState>
    ) => {
      state.email = payload.email;
      state.role = payload.role;
      state.fullname = payload.fullname;
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.id = payload.id;

    },
    resetState: (state: AuthState) => {
      state.accessToken = "";
      state.refreshToken = "";
      state.email = "";
      state.role = "";
      state.fullname = "";
      state.id = "";
      localStorage.setItem("user", "");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      refreshToken.fulfilled,
      (state: AuthState, action: PayloadAction<AuthState>) => {
        localStorage.setItem("user", JSON.stringify(action.payload));
        state.role = action.payload.role;
        state.email = action.payload.email;
        state.fullname = action.payload.fullname;
        state.accessToken = action.payload.accessToken;
        state.id = action.payload.id;
        state.refreshToken = action.payload.refreshToken;
      }
    );
  },
});

export const { setAuthenticatedUser, resetState } = authSlice.actions;
export const authReducer = authSlice.reducer;
export const selectAuthenticatedUser = (state: RootState) => state.authSlice;
