import { API_URL } from "~/config/axios";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface ILoginRequest {
  email: string;
  password: string;
}

export const authAPI = createApi({
  reducerPath: "authAPI",
  tagTypes: ["Post"],
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  endpoints: (build) => ({
    login: build.mutation<any, ILoginRequest>({
      query: ({ email, password }) => ({
        url: `auth/login`,
        method: "POST",
        body: { email, password },
      }),
    }),
  }),
});

export const { useLoginMutation } = authAPI;

export const logout = () => {
  localStorage.removeItem("user");
};
