import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { socketClient } from "~/index";
import authHeader from "~/repositories/auth-header";
import { RootState } from "../store";
import axios from "axios";
import { API_URL } from "~/config/axios";

interface IinitialState {
  Notifications: any[];
}

const initialState: IinitialState = {
  Notifications: [],
};

export const fetchMessages = createAsyncThunk(
  "fetchMessages",
  async function (_, { getState, dispatch }) {
    const socket = socketClient.socket.on("table_updates", (receivedMessages) =>
      dispatch({
        type: "notifications/saveReceivedMessages",
        payload: { Notifications: receivedMessages },
      })
    );
    return socket.connected;
  }
);

export const retrieveNotifications = createAsyncThunk(
  `notifications/retrieveNotifications`,
  async (_, { getState }) => {
    const res = await axios.get(API_URL + "/notifications", {
      headers: authHeader(),
    });
    return { Notifications: res.data };
  }
);

export const insightNotifications = createAsyncThunk(
  `notifications/insightNotifications`,
  async (_, { getState }) => {
    const state = getState() as RootState;
    const listID = state.notifcation.Notifications.map((v) => v.id);
    const res = await axios.post(
      API_URL + "/notifications/insights",
      { listID },
      {
        headers: authHeader(),
      }
    );

    return { Notifications: res.data };
  }
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    saveReceivedMessages: (state, action: PayloadAction<IinitialState>) => {
      if (action.payload?.Notifications?.length === 0) {
        state.Notifications = [];
      } else state.Notifications.push(action.payload.Notifications);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMessages.pending, () => {
      // add a state if you would like to
    });
    builder.addCase(
      fetchMessages.fulfilled,
      (state: IinitialState, action: PayloadAction<any>) => {
        // add a state if you would like to
      }
    );
    builder.addCase(fetchMessages.rejected, () => {
      // add a state if you would like to
    });
    // builder.addCase(retrieveNotifications.pending, () => {
    //   // add a state if you would like to
    // });
    builder.addCase(
      retrieveNotifications.fulfilled,
      (state, action: PayloadAction<IinitialState>) => {
        state.Notifications = action.payload.Notifications;
      }
    );
    builder.addCase(
      insightNotifications.fulfilled,
      (state, action: PayloadAction<IinitialState>) => {
        state.Notifications = action.payload.Notifications;
      }
    );
  },
});
export const notificationReducer = notificationsSlice.reducer;
