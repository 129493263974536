import axios, { AxiosRequestConfig } from "axios";
import { store } from "~/redux/store";
import { jwtDecode } from "jwt-decode";
import { API_URL } from "~/config/axios";
import { refreshToken } from "~/redux/slices/auth.slice";

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}
const refreshAndRetryQueue: RetryQueueItem[] = [];

let isRefreshing = false;

const axiosPrivate = axios.create({
  baseURL: API_URL,
});

const axiosPublic = axios.create({ baseURL: API_URL });

axiosPrivate.interceptors.request.use(
  async (config) => {
    const accessToken = store?.getState()?.authSlice?.accessToken;
    let currentDate = new Date();
    if (accessToken) {
      const decodedToken: { exp: number } = jwtDecode(accessToken);
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        await store.dispatch(refreshToken());
        if (config?.headers) {
          config.headers["Authorization"] = `Bearer ${
            store?.getState()?.authSlice?.accessToken
          }`;
        }
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosPrivate.interceptors.response.use(
  (response) =>
    new Promise((resolve) => {
      resolve(response);
    }),
  async (error) => {
    const originalRequest: AxiosRequestConfig = error.config;

    if (!error.response) {
      return new Promise((_, reject) => reject(error));
    }

    if (error.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          await store.dispatch(refreshToken());

          const newAccessToken = store?.getState()?.authSlice?.accessToken;

          // Update the request headers with the new access token
          error.config.headers["Authorization"] = `Bearer ${newAccessToken}`;

          // Retry all requests in the queue with the new token
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            axiosPrivate
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          // Clear the queue
          refreshAndRetryQueue.length = 0;

          // Retry the original request
          return axiosPrivate(originalRequest);
        } catch (refreshError) {
          // Handle token refresh error
          // clear all storage and redirect the user to the login page
          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      }
      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    } else {
      return new Promise((resolve) => resolve(error.response));
    }
  }
);

export { axiosPublic, axiosPrivate };
