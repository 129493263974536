import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import RenderRouter from "./routes";
import ThemeProvider from "~/theme/ThemeProvider";
import { AuthProvider } from "~/contexts/authContext";
import { SidebarProvider } from "~/contexts/SidebarContext";
import { useAppDispatch } from "./redux/hooks";
import { connectToSocket, disconnectFromSocket } from "./redux/slices/appSlice";
import {
  fetchMessages,
  retrieveNotifications,
} from "./redux/slices/notification.slice";
import { useAppSelector } from "~/redux/hooks";

const App: React.FC = () => {
  const connectionStatus = useAppSelector(
    (state) => state.appReducer.connectionStatus
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(connectToSocket());
    dispatch(fetchMessages());
    dispatch(retrieveNotifications());
    return () => {
      if (connectionStatus === "connected") {
        dispatch(disconnectFromSocket());
      }
    };
    //eslint-disable-next-line
  }, [dispatch]);

  return (
    <ThemeProvider>
      <BrowserRouter>
        <SidebarProvider>
          <AuthProvider>
            <RenderRouter />
          </AuthProvider>
        </SidebarProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
