import { Navigate } from "react-router-dom";
import { ReactNode } from "react";
import { useAuth } from "~/contexts/authContext";

export const ProtectedRoute = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { user } = useAuth();

  if (user.role !== "ADMIN") {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};
